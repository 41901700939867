<template>
  <dashboard-content
    hide-close-button
    watermark
  >
    <div class="patient-selected text-center">
      <div class="patient-selected-icon">
        <img
          src="@/assets/img/success.svg"
          alt=""
        >
      </div>
      <div class="patient-selected-text">
        You have selected: <br>
        <span>{{ client.name }}</span>
      </div>
    </div>
  </dashboard-content>
</template>

<script>
import { mapGetters } from 'vuex'
import DashboardContent from '@/components/dashboard/DashboardContent'

export default {
  name: 'PatientSelected',
  components: { DashboardContent },
  head: {
    title: {
      inner: 'You have selected client'
    },
    meta: [
      {
        name: 'description',
        content: 'You have selected client',
        id: 'desc'
      }
    ]
  },
  computed: mapGetters('clients', ['client']),
  watch: {
    client() {
      if (!this.client.id) {
        this.$router.push({ name: 'patientSelect' })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.patient-selected {
  padding-top: 50px;

  @media (max-width: 599px) {
    padding-top: 0;
  }

  &-icon img {
    display: block;
    margin: 0 auto;
  }

  &-text {
    margin-top: 34px;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.4;

    @media (max-width: 599px) {
      font-size: 22px;
    }

    span {
      color: var(--v-primary-base);
    }
  }
}
</style>
